/* CHAT STYLES */
* {
  font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.chats-page {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.nav-bar {
  width: 100%;
  height: 66px;
  background-color: #002766;
}

.logo-tab {
  position: absolute;
  left: 22px;
  top: 12px;
  font-size: 32px;
  font-weight: 700;
  color: white;
}

.logout-tab {
  position: absolute;
  top: 22px;
  right: 22px;
  color: white;
  cursor: pointer;
}

/* CUSTOM FEED */

.chat-feed {
  height: 100%;
  width: 100%;
  overflow: scroll;
  background-color: #ffff;
}

.chat-title-container {
  width: calc(100% - 36px);
  padding: 18px;
  text-align: center;
}

.chat-title {
  color: #1890ff;
  font-weight: 800;
  font-size: 24px;
}

.chat-subtitle {
  color: #76a6d3;
  font-weight: 600;
  font-size: 12px;
  padding-top: 4px;
}

.message-row {
  float: left;
  width: 100%;
  display: flex;
  margin-left: 18px;
}

.message-block {
  width: 100%;
  display: inline-block;
}

.message-avatar {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  color: white;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;
}

.message {
  padding: 12px;
  font-size: 16px;
  border-radius: 20px 0px 20px 20px !important;  
  max-width: 60%;
  background-color : #1890ff !important ;
}
.message-row .message{
  background-color: rgb(225, 234, 236) !important;
  border-radius: 0px 20px 20px 20px !important;
}

.message-image {
  margin-right: 18px;
  object-fit: cover;
  border-radius: 6px;
  height: 30vw;
  /* width: 30vw; */
  max-height: 200px;
  max-width: 200px;
  min-height: 100px;
  min-width: 100px;
}

.read-receipts {
  position: relative;
  bottom: 6px;
}

.read-receipt {
  width: 13px;
  height: 13px;
  border-radius: 13px;
  margin: 1.5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
}

.message-form-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 18px;
  background-color: rgba(255, 255, 255, 0);
}

.message-form {
  overflow: hidden;
  border-radius: 18px;
  width: 100%;
  border: 2px solid #3587e6;
  background-color: white;
}

.message-input {
  height: 40px;
  width: 80%;
  background-color: white;
  border: 1px solid white;
  padding: 0px 18px;
  outline: none;
  font-size: 15px;
}

.image-button {
  cursor: pointer;
  padding-right: 2px;
  height: 100%;
  padding-left: 60px;
  position: relative;
}

.send-button {
  height: 25px;
  background-color: white;
  border: 1px solid white;
  padding: 0px 20px;
  cursor: pointer;
}

.send-icon {
  
  height: 25px;
  width: 25px;
  /* top: -7px; */
  position: relative;
  /* transform: rotate(-90deg) !important; */
}s

.picture-icon {
  
  height: 100%;
  width: 100%;
  position: center;
  font-size: 14px;
}

/* FORM STYLES */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62,5%;
}

.wrapper {
  height: 100vh;
	width: 100%;
  
 /* background-color: red; */
    
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  color: #333;
  font-size: 1.2rem;
	margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255) !important;
  border-width: 5px !important;
  border-color: #002766 !important;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  outline: none;
  margin-bottom: 25px;
}
input:focus{
  background-color: rgb(255, 255, 255);
  color: #000000;
  font-weight: bold;
}

.form {
  width: 450px;
  background: #ffffff;
  height: 500px;
  padding-top: 50px;
  border-radius: 30px;
  -webkit-box-shadow: 16px 16px 25px 7px rgba(0,0,0,0.75);
-moz-box-shadow: 16px 16px 25px 7px rgba(0,0,0,0.75);
box-shadow: 16px 16px 25px 7px rgba(0,0,0,0.75);

}

.title {
  text-align: center;
  color: rgb(0, 0, 0) !important;
  margin-bottom: 30px;
  width: 100%;
  font-size: 2.3em;;
}

.button {
  border-radius: 35px !important;
  border: none;
  background: rgb(63,199,251);
background: linear-gradient(141deg, rgba(63,199,251,1) 0%, rgba(31,36,128,1) 100%);
  color: rgb(255, 255, 255) !important;
  
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  padding: 20px;
  width: 200px;
  transition: all 0.4s;
  cursor: pointer;
  margin: 5px;
  width: 90%;
}

.button:hover{
  color: rgb(0, 0, 0);
  box-shadow: 0 5px 15px rgb(82, 220, 254);

}
.button:focus{
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px rgb(82, 220, 254);
}
.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
  
}
.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
  
  
}
.button:hover span {
  padding-right: 25px;
  
}
.button:hover span:after {
  opacity: 1;
  right: 0;
  
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}



.wrapper {
  height: 100vh;
  width: 100%;
  background: rgb(63,199,251);
background: linear-gradient(343deg, rgba(63,199,251,1) 0%, rgba(31,36,128,1) 100%);
  justify-content: center;
  align-items: center;
}

.input {
  color: #333;
  font-size: 1.2rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 45px;
  background-color: rgb(234, 227, 227);
  border-color: rgb(149, 49, 49);
  
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  outline: none;
  margin-bottom: 25px;
}

/* .input:focus{
  color: rgb(0, 242, 255);
  color: linear-gradient(82deg, rgb(0, 242, 255) 46%, rgb(0, 229, 255) 100%);
} */



.title {
  text-align: center;
  color: white;
  margin-bottom: 30px;
  width: 100%;
  font-size: 2.3em;
}

.button {
  border-radius: 4px;
  border: none;
  background-color: white;
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  padding: 20px;
  width: 200px;
  transition: all 0.4s;
  cursor: pointer;
  margin: 5px;
  width: 90%;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}

#logo{
  height: 50px !important;
  padding-left: 30px;
}

.c{
  align-items: center;
  font-size: 20px;
  color: red;
  padding-left: 60px;
}
